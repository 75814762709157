const NAV_OFFCANVAS = '.js-nav-offcanvas'
const NAV_OFFCANVAS_TRIGGER = '.js-nav-offcanvas-trigger'
const OVERLAY = '.js-nav-offcanvas-overlay'

class NavOffcanvas {
  constructor() {
    this.navOffcanvasTrigger = $(NAV_OFFCANVAS_TRIGGER)
    this.navOffcanvas = $(NAV_OFFCANVAS)
    this.overlay = $(OVERLAY)
  }

  bind() {
    $(document).on('click', NAV_OFFCANVAS_TRIGGER, this.toggleNavOffcanvas.bind(this))
    $(document).on('click', OVERLAY, this.hideNavOffcanvas.bind(this))

    document.addEventListener('keydown', (e) => {
      if (e.key == "Escape") {
        this.hideNavOffcanvas();
      }
    });

  }

  toggleNavOffcanvas(event) {
    event.preventDefault()
    $(document).find(NAV_OFFCANVAS_TRIGGER).toggleClass('is-active').attr('aria-expanded', (_, value) => { return value === "false" ? "true" : "false" })
    $(document).find(NAV_OFFCANVAS).toggleClass('is-active').attr('aria-expanded', (_, value) => { return value === "false" ? "true" : "false" })
    this.overlay.toggleClass('is-active')
    $('body').toggleClass('is-nav-offcanvas-active')
  }

  hideNavOffcanvas(event) {
    $(document).find(NAV_OFFCANVAS_TRIGGER).removeClass('is-active')
    $(document).find(NAV_OFFCANVAS).removeClass('is-active').attr('aria-expanded', false)
    this.overlay.removeClass('is-active')
    $('body').removeClass('is-nav-offcanvas-active')
  }
}

$(function() {
  const navOffcanvas = new NavOffcanvas
  navOffcanvas.bind()
})
