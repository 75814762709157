export default class BootstrapModal {
  constructor(element) {
    this.element = element
    this.modal = $(element.data('target') || element.attr('href'))
    this.render()
  }

  render() {
    if (this.modal.length > 0 && this.element.data('targetContent')) {
      let targetContent = $(this.element.data('targetContent'))
      this.modal.find('.modal-content').html(targetContent.children())
      this.modal.one('hidden.bs.modal', () => {
        let targetContent = $(this.element.data('targetContent'))
        targetContent.html(this.modal.find('.modal-content').children())
      })
    }
  }

  on() {
    return this.modal.on.apply(this.modal, arguments)
  }

  one() {
    return this.modal.one.apply(this.modal, arguments)
  }

  hide() {
    return this.modal.modal('hide')
  }

  show() {
    return this.modal.modal('show')
  }
}

export function showOnPageLoad() {
  $('.modal[data-modal-show="true"]').removeAttr('data-modal-show').modal()
  $('.modal:has([data-modal-show="true"])').modal().find('[data-modal-show]').removeAttr('data-modal-show')
}

$(function() {
  showOnPageLoad()

  $(document).on('click.bs.modal.data-api', '[data-toggle="modal-no-focus"]', function (e) {
    e.preventDefault()
    new BootstrapModal($(this)).show()
  });

  $(document).on('shown.bs.modal', (e) => $('[autofocus]', e.target).focus());
});
