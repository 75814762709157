import { navigateTo } from 'javascripts/consumer/utils/navigation'

const redirectToLocation = xhr => {
  let location = xhr.getResponseHeader('Location');

  if (location && location != window.location.pathname) {
    navigateTo({}, 'Reactivated', location);
  }
}

$(() => {
  $(document).on('ajax:complete', '[data-fragmentable]', event => {
    let [xhr, status] = event.detail;
    if (xhr.responseText.length === 0) return;
    redirectToLocation(xhr)

    let promise = new $.Deferred
    $(document).one('fragments:beforeUpdate', () => promise.resolve())

    $.when($(event.target).trigger('fragments:beforeUpdate', [promise, xhr, status]), promise).done(() => {
      $(`<div>${xhr.responseText}</div>`).find('[data-fragment]').each((_i, element) => {
        let $element = $(element)
        let replacingElement = $(`[data-fragment='${$element.data("fragment")}']:last`)
        let data = { xhr, target: event.target };

        replacingElement.trigger(`fragment:beforeUpdate`, [replacingElement, $element, xhr, status])

        replacingElement.replaceWith($element)

        $element.trigger(`fragment:updated:${$element.data("fragment")}`, [replacingElement, $element, data])
        $element.trigger('fragment:updated', [replacingElement, $element, data])
      })

      $(event.currentTarget).trigger('fragments:updated', [xhr])
    })
  });
});
