import Rails from '@rails/ujs'

const captchaLoaded = new $.Deferred

export default class Recaptcha {
  constructor(container) {
    this.container = container
    this.render()
  }

  render() {
    this.container.find('.js-recaptcha').each((_index, element) => this.loadCaptcha(element));
  }

  loadCaptcha(element) {
    captchaLoaded.then(() => grecaptcha.render(element))
  }
}

export function recaptchaOnloadCallback() { captchaLoaded.resolve() }
export function invisibleRecaptchaSubmit(_token) {
  const $form = $('.js-recaptcha-form:visible');
  if ($form.data('remote')) {
    Rails.fire($form[0], 'submit');
  } else {
    $form.trigger('submit');
  }
}
