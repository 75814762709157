import { getUrlParameter } from 'javascripts/consumer/utils/navigation'
import Cookies from 'js-cookie'

$(function() {
  if (window.location.pathname == '/') {
    captureUrlParameters()
  }
})

function captureUrlParameters() {
  const params = {
    'coupon': 'coupon_code',
    'redeem': 'gift_code',
    'utm_campaign': 'utm_campaign',
    'utm_source': 'utm_source',
    'utm_medium': 'utm_medium'
  }
  for (let key in params) {
    let sessionKey = params[key];
    let paramValue = getUrlParameter(key)
    if (paramValue != null && paramValue != '') {
      let expires = (key == 'utm_campaign' || key == 'utm_source' || key == 'utm_medium') ? { expires: 7 } : undefined;
      Cookies.set(sessionKey, paramValue, expires);
    }
  }
}
