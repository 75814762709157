function toggleInputType(e) {
  e.preventDefault();
  $(this).find('.js-password-toggle--svg-icon').toggleClass('d-none');
  var input = $(this.parentElement).find('input');

  if (input.attr('type') == 'password') {
    input.attr('type', 'text');
  } else {
    input.attr('type', 'password');
  }
}

$(document).on('click', '.js-password-toggle', toggleInputType)
