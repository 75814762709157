const ERROR_STATUS_CODE = 422;

class AlertsAndValidationsFocus {
  static focusValidationError(form) {
    const inputToFocus = form.find('.is-invalid:first').find('input:not([type="submit"]),textarea,select,[role="listbox"]')

    if (inputToFocus.length > 0) {
      inputToFocus.focus()
    } else {
      AlertsAndValidationsFocus.focusAlertMessage('.js-alert-error')
    }
  }

  static focusAlertMessage(alertSelector = '.js-alert-success') {
    const alertMessage = $(`${alertSelector}:first`)
    if (alertMessage.length > 0) alertMessage.focus();
  }
}

$(function() {
  AlertsAndValidationsFocus.focusAlertMessage()

  $(document).on('fragments:updated', function(_event, xhr) {
    if (xhr.status === ERROR_STATUS_CODE) {
      AlertsAndValidationsFocus.focusValidationError($(this))
    } else {
      AlertsAndValidationsFocus.focusAlertMessage()
    }
  })
});
