// Images
require.context('../images/consumer', true)

// STYLES
import '../stylesheets/homepage_content'

// JS
import Rails from "@rails/ujs"
import 'bootstrap/js/dist/modal'
import 'javascripts/consumer/components/bootstrap_modal'
import 'javascripts/consumer/components/bootstrap_ios_modal'
import 'bootstrap/js/dist/carousel'
import 'javascripts/styleguide/tpc/components/_password_toggle'
import 'javascripts/data_fragmentable'
import 'javascripts/consumer/components/ab_tests/vwo_runner'
import 'javascripts/consumer/components/analytics/heap_identity'
import 'javascripts/consumer/components/analytics/heap_vwo'
import 'javascripts/consumer/components/trackable'
import 'javascripts/consumer/components/slides'
import 'javascripts/consumer/components/alerts_and_validations_focus'

import 'javascripts/consumer/components/nav_offcanvas'
import 'javascripts/consumer/components/sign_up_modal'
import 'javascripts/consumer/components/animated_section'
import 'javascripts/consumer/components/capture_url_parameters'
import 'javascripts/consumer/components/chatbot_widget'

// Disable iOS zoom on focus behavior for inputs
import 'javascripts/consumer/components/disable_ios_autozoom'

import { recaptchaOnloadCallback, invisibleRecaptchaSubmit } from 'javascripts/consumer/components/recaptcha'

window.$ = require('jquery');
Rails.start();
window.recaptchaOnloadCallback = recaptchaOnloadCallback
window.invisibleRecaptchaSubmit = invisibleRecaptchaSubmit

