class Slides {
  constructor(slider) {
    this.slider = slider
    this.slides = slider.find('.js-slides-item')
    this.control = $(slider.data('control'))
    this.dots = this.control.find('.js-slides-dot')
    this.index = 0

    this._bind()
  }

  show() {
    if (this.index >= this.slides.length) { this.index = 0 }
    if (this.index < 0) { this.index = (this.slides.length) - 1 }

    this.slides.hide()
    this.dots.removeClass('is-selected')

    $(this.slides[this.index]).show()
    $(this.dots[this.index]).addClass('is-selected')
  }

  _bind() {
    this.control.on('click', '.js-slides-next', this._pushSlides.bind(this, 1))
    this.control.on('click', '.js-slides-prev', this._pushSlides.bind(this, -1))
  }

  _pushSlides(steps, event) {
    event.preventDefault()

    this.index += steps
    this.show()
  }

}

$(function() {
  $('.js-slides').each(function() {
    new Slides($(this)).show()
  })
})
