import currentUserData from 'javascripts/consumer/models/current_user_data'
import Cookies from 'js-cookie'

const MAX = 3650
const FIRST_TIME_VISIT = 'user-first-time-visit'
const SESSION_TYPE = 'user-visit-session-type'
const TRAFFIC_TYPE = 'user-visit-traffic-type'

class Trackable {
  constructor() {
    Cookies.set(SESSION_TYPE, this.sessionType(), { expires: MAX })
    this.setCreatedAccount()
    this.setSignedUp()
    this.setTrafficType()
  }

  sessionType() {
    const sessionType = Cookies.get(SESSION_TYPE)

    if (currentUserData || (sessionType && !Cookies.get(FIRST_TIME_VISIT))) {
      Cookies.remove(FIRST_TIME_VISIT)
      return 'returning'
    }

    if (!sessionType || Cookies.get(FIRST_TIME_VISIT)) {
      Cookies.set(FIRST_TIME_VISIT, true)
      return 'first_time'
    }
  }

  setCreatedAccount() {
    if (currentUserData) Cookies.set('user-visit-created-acount', true, { expires: MAX })
  }

  setSignedUp() {
    if (currentUserData && currentUserData['user-subscription-status'] !== 'inactive') {
      Cookies.set('user-visit-signed-up', true, { expires: MAX })
    }
  }

  setTrafficType() {
    if(Cookies.get(TRAFFIC_TYPE)) { return }
    if (!this.hasCoupon() && this.hasUtm()) {
      Cookies.set(TRAFFIC_TYPE, 'paid_utm_no_coupon', {expires: MAX})
    } else if (this.hasCoupon() || this.hasUtm()) {
      Cookies.set(TRAFFIC_TYPE, 'paid_coupon_or_utm', {expires: MAX})
    } else {
      Cookies.set(TRAFFIC_TYPE, 'organic', { expires: MAX })
    }
  }

  hasCoupon() {
    return /coupon=/.test(window.location.search) || Cookies.get('coupon_code')
  }

  hasUtm() {
    return /utm_source=|utm_campaign=|utm_medium=/.test(window.location.search)
  }
}

new Trackable()
