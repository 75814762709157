import Cookies from 'js-cookie'

const COOKIE_OPTIONS = {expires: 60}

document.addEventListener('DOMContentLoaded', initVwoTest);

export const initVwoTest = () => {
  if(!gon.vwo_tests) return

  for (let testName of Object.keys(gon.vwo_tests)) {
    setUpTest(
      testName,
      Object.keys(gon.vwo_tests[testName]).map(e => gon.vwo_tests[testName][e])
    )
  }
}

// testData is array of objects, i.e. [{vwo_test_id: 1, traffic: '..', ...}]
function setUpTest(testName, testsData) {
  if(Cookies.get(`disabled_${testName}`)) {
    return;
  }

  const enabledTests = Cookies.get(`enabled_${testName}`)
  let disableTest = false;

  if(enabledTests) {
    enableTest(testName, JSON.parse(enabledTests))
  } else {
    for(let vwoTestData of testsData) {
      const criteriaMet = meetsCriteria(vwoTestData)

      if(criteriaMet === true) {
        enableTest(testName, [vwoTestData.vwo_test_id])
        break;

      } else if(criteriaMet === false) {
        disableTest = true
      }
    }
  }
  if(disableTest && !testEnabled(testName)) {
    Cookies.set(`disabled_${testName}`, 'true', COOKIE_OPTIONS)
  }
}

function enableTest(testName, vwoTestIds) {
  const enabledIds = Cookies.get(`enabled_${testName}`)
  if(enabledIds) {
    let array = JSON.parse(enabledIds)
    array = [...new Set(array.concat(vwoTestIds))]
    Cookies.set(`enabled_${testName}`, JSON.stringify(array), COOKIE_OPTIONS)
  } else {
    Cookies.set(`enabled_${testName}`, JSON.stringify(vwoTestIds), COOKIE_OPTIONS)
  }
  enableVWO(vwoTestIds)
}

// returns true, false or undefined based on if the test should be enabled,
// disabled, or no action should be taken (respectively)
function meetsCriteria(testCriteria) {
  if (testCriteria.exclusive_with && anyEnabled(testCriteria.exclusive_with)) return false

  if(testCriteria.enabled != undefined && testCriteria.enabled.toString() == "false") {
    return undefined // don't disable completely, so that it can still be enabled when the test is enabled later
  }

  if(testCriteria.url_path != undefined) {
    if(testCriteria.url_path.includes != undefined && location.pathname != testCriteria.url_path.includes) {
      return undefined // ignore until the user navigates to expected page
    }

    if(testCriteria.url_path.excludes != undefined && location.pathname == testCriteria.url_path.excludes) {
      return undefined // ignore until the user navigates to expected page
    }
  }

  if(testCriteria.subscription_statuses != undefined){
    if(!window.currentUserData) {
      return  undefined // we don't want to enable or disable the test unless the user signs in
    }
    const status = window.currentUserData['user-subscription-status']
    const expected_statuses = JSON.parse(testCriteria.subscription_statuses)
    if(!expected_statuses.includes(status)) {
      return false
    }
  }

  if(testCriteria.session_type != undefined) {
    if(testCriteria.session_type != Cookies.get('user-visit-session-type')) {
      return false
    }
  }

  if(testCriteria.traffic != undefined) {
    if(testCriteria.traffic != Cookies.get('user-visit-traffic-type')) {
      return false
    }
  }

  if(testCriteria.created_account != undefined) {
    const created_account = Cookies.get('user-visit-created-acount') || 'false'
    if(testCriteria.created_account != created_account){
      return false
    }
  }

  return true
}

function testEnabled(testName) {
  return Cookies.get(`enabled_${testName}`)
}

function anyEnabled(tests) {
  return tests.some(function(test) {
    return Cookies.get(`enabled_${test}`)
  })
}

function enableVWO(testIds) {
  window.VWO = window.VWO || []
  window.VWO.push(['activate', false, testIds.map(numStr => parseInt(numStr)), true])
}
