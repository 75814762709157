import FontFaceObserver from 'fontfaceobserver'

const ANIMATED_SECTION = '.js-animated-section'
const ANIMATED_CLASS = 'is-animated'
const FONTS = [
  "Mabry",
  "Open Sans"
]

const observers = [ ]

FONTS.forEach(function(family) {
  observers.push(new FontFaceObserver(family))
});

class AnimatedSection {
  constructor(element) {
    this.element = element
    this.options = { threshold: this.element.data('threshold') || 0.3 }
    this.observer = new IntersectionObserver(this._setIsAnimated.bind(this), this.options);

    this.observer.observe(element[0])
  }

  _setIsAnimated(entries) {
    if (!entries[0].isIntersecting) return

    Promise.all(observers).then(() => {
      this.element.addClass(ANIMATED_CLASS)
      this.observer.unobserve(this.element[0])
    })
  }
}

$(function() {
  $(ANIMATED_SECTION).each(function() {
    new AnimatedSection($(this))
  })
})
