// Add the following JS code to VWO JS editor for Variant1
//
// (function () {
//  window.jsPlanStepInSignup = true;
//  function redirectFromTo(requiredPath, requiredParams, toUrl) {
//    if (window.currentUserData || !(requiredPath.test(window.location.pathname) && requiredParams.test(window.location.search))) return;
//
//    var search = window.location.search.substr(1);
//    search = search.replace(/([&,?])?signup=modal/, '');
//
//    if (search.length >= 1) { toUrl = toUrl + "&" + search; }
//    window.location.href = toUrl;
//  }
//
//  redirectFromTo(/\/plans/, /signup=modal/, '/sign_up/account?f=4');
// })();
//
import Cookies from 'js-cookie'
import currentUserData from 'javascripts/consumer/models/current_user_data'
import Coupon from 'javascripts/consumer/models/coupon'
import { getUrlParameter, removeURLParameter, replaceURLParameter } from 'javascripts/consumer/utils/navigation'
import Recaptcha from 'javascripts/consumer/components/recaptcha'

const PLANS_PATH = /\/plans/;
const MENU_PATH = /\/weekly-menu/;
const REQUIRED_URL_PARAMS_SIGNUP = /signup=modal/;
const REQUIRED_URL_PARAMS_LOGIN = /login=modal/;
const SIGNUP_FORM = '.js-new-user-form';
const SIGNUP_OMNIAUTH_LINKS = '.js-plans__selected .js-omniauth-button';

export function showDefaultCoupon(url) {
  var selectedPlan = getUrlParameter('plan', url || window.location.href) || Cookies.get('last_signup_modal_plan_viewed');
  url = (url == "[object SVGAnimatedString]") ? url.animVal : url

  if (url && url.indexOf('&extra_meals[]=') != -1) {
    const extras = url.split('&extra_meals[]=')
    const extraMeals = "&extra_meals[]=" + extras.splice(1,extras.length).join('&extra_meals[]=')

    if (selectedPlan) {
      let newPath = removeURLParameter($(SIGNUP_FORM).attr('action'), 'extra_meals[]');
      let deliveryFrequency = getUrlParameter('delivery_frequency', url);

      if (deliveryFrequency) {
        newPath = removeURLParameter(newPath, 'delivery_frequency');
        deliveryFrequency = `&delivery_frequency=${deliveryFrequency}`;
      }

      $(SIGNUP_FORM).attr('action', newPath + extraMeals + deliveryFrequency);
      $(SIGNUP_OMNIAUTH_LINKS).each(function(i, e) {
        newPath = $(e).attr('href');
        newPath = replaceURLParameter(newPath, 'signup_plan', selectedPlan);
        newPath = removeURLParameter(newPath, 'extra_meals');
        newPath = removeURLParameter(newPath, 'delivery_frequency');
        $(e).attr('href', newPath + extraMeals + deliveryFrequency);
      });
    }
  }

  if (selectedPlan) {
    Cookies.set('last_signup_modal_plan_viewed', selectedPlan);
    $('.js-signup-form-plan').val(selectedPlan);
  }

  Coupon.findForPlan(selectedPlan, (coupon) => {
    if (coupon) {
      $('.js-signup-form__discount-val').text(coupon.discount);
      if (coupon.max_order_count > 1) {
        $('.js-signup-form__single-order').toggleClass('d-none', true);
        $('.js-signup-form__multi-orders').toggleClass('d-none', false);
      } else {
        $('.js-signup-form__single-order').toggleClass('d-none', false);
        $('.js-signup-form__multi-orders').toggleClass('d-none', true);
      }
    } else {
      $('.js-signup-form__discount-val').text('');
    }
    $('.js-signup-login-modal__default-coupon').toggleClass('d-none', !coupon);
    $('.js-signup-login-modal__without-coupon').toggleClass('d-none', !!coupon);
  });
}

function rewriteFinishSignupLinks() {
  if (!currentUserData || !currentUserData.navigation_link) return;

  $("a.js-nav-link, a[href*='/users/sign_up'], a[href*='/subscription/plan'], .js-sign-up-modal").each(function(i, e) {
    if (currentUserData.navigation_link) {
      $(e).prop('href', currentUserData.navigation_link);
    }
  });
}

function SignUpModal() {
  this.isLoggedIn = currentUserData != undefined;
  this.$signUpModal = $('.js-signup-login-modal');
  this.$defaultCoupon = this.$signUpModal.find('.js-signup-login-modal__default-coupon');
}

SignUpModal.prototype.render = function() {
  if (this.isLoggedIn || this.$signUpModal.length <= 0) return;
  this.$signUpModal.on('signup:form_updated', () => {
    new Recaptcha(this.$signUpModal);
    if (this.$signUpModal.find('.js-signup-form:visible').length > 0) {
      showDefaultCoupon();
    }
  })

  this.openModalOnSignInClick()
  this.showModalIfPath('login', MENU_PATH, REQUIRED_URL_PARAMS_LOGIN);

  this.openModalOnSignUpClick()
  this.showModalIfPath('signup', PLANS_PATH, REQUIRED_URL_PARAMS_SIGNUP);
}

SignUpModal.prototype.showModalIfPath = function(form, path, params) {
  if (!(path.test(window.location.pathname) && params.test(window.location.search))) return;
  if (isAbSignupPlnaEnabled()) return

  this.showModal(form);
}

SignUpModal.prototype.openModalOnSignInClick = function() {
  var self = this;

  $(document.body).on('click', "a[href*='/users/sign_in'], .js-sign-up-modal", function(e) {
    var trigger = this;

    e.preventDefault();
    self.$signUpModal.one("hidden.bs.modal", function() { trigger.focus() });
    self.$defaultCoupon.addClass('d-none');
    self.showModal('login');
    if($(e.currentTarget).data('show-signup-link') == false){
      self.$signUpModal.find('.js-login-form .js-signup-login-modal__login-block').hide();
      self.$signUpModal.find('.js-login-form form').append('<input type="hidden" name="continue_to_signup" value="true">');
    }
  });
}

SignUpModal.prototype.openModalOnSignUpClick = function() {
  var self = this;

  $(document.body).on('click', "a[href*='/users/sign_up'], .js-sign-up-modal", function(e) {
    if (isAbSignupPlnaEnabled()) return

    e.stopImmediatePropagation();
    e.preventDefault();
    self.showModal('signup', this.href);
    self.$signUpModal.find('.close').focus();
  });

}

SignUpModal.prototype.showModal = function(signup, link) {
  var isSignup = signup == 'signup';
  if(isSignup) {
    showDefaultCoupon(link);
  } else {
    this.$defaultCoupon.addClass('d-none');
  }

  this.$signUpModal.find('.js-signup-form').toggleClass('d-none', !isSignup);
  this.$signUpModal.find('.js-login-form').toggleClass('d-none', isSignup);
  this.$signUpModal.attr('aria-labelledby', 'signup-login-modal-title-' + (isSignup ? 'signup' : 'login'))
  new Recaptcha(this.$signUpModal)

  this.$signUpModal.modal();
}

$(function() {
  new SignUpModal().render();

  if (isAbSignupPlnaEnabled()) return
  rewriteFinishSignupLinks();
});

function isAbSignupPlnaEnabled() {
  return false
}
