export function replaceUrl() {
  window.history.replaceState.apply(window.history, arguments)
  updateCanonicalLink()
}

export function navigateTo() {
  window.history.pushState.apply(window.history, arguments)
  updateCanonicalLink()
}

function updateCanonicalLink() {
  var linkCanonical = $('link[rel="canonical"]')
  var url = [location.protocol, '//', location.host, location.pathname].join('')

  linkCanonical.attr('href', url);
}

export function getUrlParameter(name, search = location.search) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export function addParameterToURL(url, param) {
  url += (url.split('?')[1] ? '&' : '?') + param;
  return url;
}

export function replaceURLParameter(url, paramName, paramValue) {
    if (paramValue == null) {
      paramValue = '';
    }

    var pattern = new RegExp('\\b('+encodeURIComponent(paramName)+'=).*?(&|#|$)');

    if (url.search(pattern) >= 0) {
      return url.replace(pattern,'$1' + paramValue + '$2');
    }

    return url + (url.split('?')[1] ? '&' : '?') + encodeURIComponent(paramName) + '=' + paramValue;
}

export function removeURLParameter(url, paramName) {
  const queryString = url.split('?')[1]
  var url = url.split('?')[0]
  const params = new URLSearchParams(queryString);
  params.delete(paramName);

  return `${url}${params.toString() ? '?' + params.toString() : ''}`
}
