const NAME = 'zEWidget';
const ZENDESK_WIDGET_ID = '#launcher';
const ZENDESK_BUTTON = '.js-chatbot-widget-cta'

class ZendeskWidget {
  constructor(target) {
    this.target = $(target);

    this._render()
  }

  _render() {
    if (!this.target.data(NAME)) {
      this.target.data(NAME, 'true')
      this._bind()
    }

    window.zE('webWidget', 'open');
  }

  _bind() {
    window.zE('webWidget:on', 'open', this._moveFocusToWidget.bind(this));
    window.zE('webWidget:on', 'close', this._moveFocusToTarget.bind(this));
  }

  _moveFocusToWidget() {
    $(ZENDESK_WIDGET_ID)[0].contentWindow.focus();
  }

  _moveFocusToTarget() {
    this.target.trigger('focus');
  }
}

const openChat = target => {
  if (window.adaSettings) {
    window.adaEmbed.toggle();
  } else if (window.zESettings) {
    new ZendeskWidget(target);
  }
}

$(document)
  .on('click', ZENDESK_BUTTON, event => openChat(event.target))
  .on('keypress', ZENDESK_BUTTON, (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    openChat(event.target);
  }
});
