import Cookies from 'js-cookie'
import { getUrlParameter } from 'javascripts/consumer/utils/navigation';

const COUPON_KEY = 'signupCoupons';

const Coupon = {
  successCallback: () => null,
  selected_plan: null,
  findForPlan(selected_plan, successCallback) {
    let couponCode = getUrlParameter('coupon') || Cookies.get().coupon_code;
    this.selected_plan = selected_plan;
    this.successCallback = successCallback;

    if (couponCode) {
      requestCoupon(couponCode)
    } else {
      setCoupon({});
    }
  }
}

const allowedCoupon = (selected_plan, discount, allowed_plans) =>
  discount && (!selected_plan || allowed_plans.includes(selected_plan));

const setCoupon = (coupon) => {
  let discount = coupon['discount']
  let allowed_plans = coupon['allowed_plans'] || [];

  if (allowedCoupon(Coupon.selected_plan, discount, allowed_plans)) {
    Coupon.successCallback(coupon)
    return;
  }

  if (gon.default_signup_coupon && gon.default_signup_coupon.discount) {
    Coupon.successCallback(gon.default_signup_coupon);
  } else {
    Coupon.successCallback(null);
  }
}

const requestCoupon = (coupon_code) => {
  let coupons = (Cookies.getJSON(COUPON_KEY) || {});
  let currentCoupon = coupons[coupon_code]

  if (currentCoupon) {
    setCoupon(currentCoupon);
  } else {
    $.ajax({
      url: `/coupons/${coupon_code}`,
      success(data) {
        coupons[coupon_code] = data
        Cookies.set(COUPON_KEY, coupons, { expires: 2 });
        setCoupon(data);
      },
      error() {
        setCoupon({});
      }
    });
  }
}

export default Coupon
