import isIosDevice from 'javascripts/consumer/utils/is_ios_device.js'

$(document).on('shown.bs.modal', '.js-modal-with-inputs', function(event) {
  const modal = $(event.currentTarget)

  if(!isIosDevice() || modal.data('ignore-ios-fix')) return;

  const originParent = modal.parent()
  const modalContentHeight = modal.find('.modal-content').height()
  const vpH = Math.max(window.innerHeight, modalContentHeight);

  $(document.documentElement).css({'height': `${vpH}px`, 'overflow': 'hidden'})
  $(document.body).css({'height': `${vpH + 1}px`, 'overflow': 'hidden'})
  $(document.body).append(modal.detach())
  window.scrollTo(0, 1)

  modal.css({'min-height': `${modalContentHeight}px`});

  modal.one('hide.bs.modal', function() {
    originParent.append(modal.detach())

    $(document.documentElement).css({'height': '', 'overflow': ''})
    $(document.body).css({'height': '', 'overflow': ''})
    modal.css({'min-height': ''})

    return true
  })
})
